.index_container {
  width: 100%;
  height: 100%;

  .image_index_container {
    width: 100%;
    height: 100%;
    color: #fff;
    background-color: #32333D;
  }

  .loding_icon{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    font-size: 42rem;
    .img_loading{
      color: #1677ff;
      font-size: 28rem;
    }
  }

  .title {
    height: 87rem;
    justify-content: center;

    .circle {
      width: 6rem;
      height: 6rem;
      border-radius: 50%;
      background-color: #fff;
      margin: 0 16rem;
    }

    div {
      font-size: 32rem;
    }
  }

  .score_list {
    min-width: 100%;

    .row_item {
      flex: 1;
      color: #000000;
      font-size: 18rem;
      height: 55rem;
      text-align: center;
      max-width: 64rem;
      border-right: 2rem solid transparent;

      &:first-child {
        min-width: 172rem;
        padding-left: 30rem;
      }

      &:last-child {
        border-right: none !important;
      }

      &.total_out {
        min-width: 100rem;
        background-color: rgba($color: #D7CAB7, $alpha: 0.28);
        color: #000000 !important;
      }
    }

    .rows {
      background-color: #fff;
      height: 55rem;
      border-bottom: 2rem solid #32333D;
      

      &:first-child {
        height: 50rem;

        .row_item {
          height: 50rem;
          line-height: 50rem;
          border-right: 2rem solid #32333D;
        }
      }

      &.red_rows {
        background-color: #A44248;

        .row_item {
          color: #fff;
        }
      }

      &.blue_rows {
        background-color: #6B92CD;

        .row_item {
          color: #fff;
        }
      }


    }

    .user_score_rows {
      background-color: #fff;
      border-bottom: 2rem solid #32333D;


      &:first-child {
        background-color: #FFF5F5;

        .row_item {
          
          &:first-child {
            border-left: 4rem solid #F1353B;
          }

        }

      }

      .row_item {
        border: 1rem solid #fff;
        border-right: none;
        color: #fff;

        &:first-child {
          color: #000000;
          border: none;
          border-left: 4rem solid transparent;
          .username {
            width: 100%;
            padding-left: 10rem;
            text-align: left;
            text-overflow: -o-ellipsis-lastline;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            line-clamp: 1;
            -webkit-box-orient: vertical;
            word-break: break-all;
          }
        }

        .avartar {
          width: 36rem;
          height: 36rem;
          border-radius: 50%;
          margin-right: 6rem;
        }


      }
    }
  }

  .share_img {
    width: 100%;
    height: auto;
  }

  .tip_mode {
    width: 432rem;
    height: 180rem;
    border-radius: 12rem;
    background-color: rgba($color: #000000, $alpha: 0.6);
    color: #fff;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 28rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    opacity: 0;

    &.show {
      animation: hide 5s;
    }

    img {
      width: 48rem;
      height: 48rem;
      margin-bottom: 34rem;
    }
  }
}


@media screen and (max-width:500px) {

  // 竖屏
  .image_index_container {
    width: 100vh !important;
    height: 100vw !important;
    transform-origin: left bottom;
    transform: rotate(90deg) translateX(-100vw);
  }
}

//提示隐藏
@keyframes hide {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@-webkit-keyframes hide {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}