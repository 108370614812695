.flex {
  display: flex;
  align-items: center;
}

.flex_center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex_btw {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.mr-5 {
  margin-right: 5rem;
}

.ml-10 {
  margin-left: 10rem;
}
.mr-20{
  margin-right: 20rem;
}
.mb-20{
  margin-bottom: 20rem;
}
.container {
  padding: 0 32rem;
}

.video_page {
  .video_box {
    width: 100%;
    position: relative;
    overflow: hidden;
    .loading_box{
      width: 100%;
      height: 100%;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%,-50%);
      background-color: rgba(0, 0, 0, 0.3000);
      .play_img{
          width: 88rem;
          height: 88rem;
          z-index: 99;
      }
    }
    video {
      width: 100%;
      display: block;
    }

    .control_box {
      width: 100%;
      height: 87rem;
      position: absolute;
      padding-right: 32rem;
      padding-left: 16rem;
      bottom: 0;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8000) 100%);
      backdrop-filter: blur(0px);
      transform: translateY(100%);
      transition: transform 1s;
      &.is_playing{
        transform: translateY(0);
      }

      .click_area {
        padding: 0 16rem;
        height: 100%;
      }

      .control_icon {
        width: 40rem;
        height: 40rem;
      }

      .play_time {
        color: #fff;
        font-size: 26rem;
      }

      .icon_img {
        width: 32rem;
        height: 32rem;
      }

      .mySlider {
        --fill-color: #00D47D;
        margin: 0 10rem;
      }

      .adm-slider {
        width: 320rem;
        flex-shrink: 0;

        .adm-slider-thumb-container {
          width: 32rem;
          height: 32rem;
        }

        .adm-slider-thumb {
          width: 26rem;
          height: 26rem;
          margin: 2rem;
          background-color: #00D47D;
        }

        .adm-slider-thumb-icon {
          width: 0rem;
          height: 0rem;
          display: none;
        }
      }
    }
  }
  .video_title {
    font-weight: 600;
    font-size: 32rem;
    color: rgba(0, 0, 0, 0.8000);
    padding-left: 32rem;
    padding-top: 32rem;
  }
  .video_list {
		width: 100%;
		flex-wrap: wrap;
    padding: 32rem 32rem 0;
    .myregdate{
      font-size: 24rem;
      text-align: center;
      color: rgba(0, 0, 0, 0.4);
    }
		.video_item {
			width: 332rem;
			height: 247rem;
      margin-bottom: 10rem;
			border-radius: 24rem;
			overflow: hidden;
			position: relative;
      border: none;
      .overall{
				position: absolute;
				left: 0;
				top: 25rem;
				width: 68rem;
				height: 36rem;
        line-height: 36rem;
				text-align: center;
				font-size: 22rem;
				border-radius: 0 18rem 18rem 0;
				background-color: rgba(0, 0, 0, 0.2);
				color: #fff;
			}

			.video_img {
				width: 332rem;
				height: 247rem;
        border-radius: 24rem;
			}

			.bottom_tip {
				width: 100%;
				height: 64rem;
				position: absolute;
				bottom: 0;
				left: 0;
				background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.1000) 100%);
				border-radius: 0px 0px 24rem 24rem;
				backdrop-filter: blur(4rem);
				padding-left: 28rem;

				.text {
					color: #fff;
					margin-left: 8rem;
          font-size: 24rem;
				}
        .icon_img{
          width: 32rem;
          height: 32rem;
        }
			}
		}
	}

  .video_contanier {
    background: #F5F6FA;

    .person_info {
      height: 98rem;
      .person_avartar{
        width: 72rem;
        height: 72rem;
        border-radius: 50%;
      }
      .myregdate{
        font-size: 24rem;
      }
      .person_name {
        font-weight: 400;
        color: rgba(0, 0, 0, 0.8000);
        font-size: 30rem;
      }
    }
  }
}