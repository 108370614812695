.scan_code_container{
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 0 84rem;
  .scan_code_img{
    width: 100%;
    height: auto;
  }
  .scan_text{
    margin-top: 50rem;
    font-size: 15rem;
    text-align: center;
    color: #D0D0D0;
  }
}